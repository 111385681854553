import { h } from "preact"
import Footer from '../components/footer'
import { Category, DoubleOptInType, PermissionLinkStatus, Rule, ValidationError } from '../domain'
import ConsentInformation from '../components/consent-information'
import Title from '../components/title'
import Description from '../components/description'
import { hasValidationErrors, validateConsentCheck, validateEmail } from '../functions/validation'
import { EviAPI } from '../evi-api'
import CategoryList from '../components/category-list'
import EmailSubmit from '../components/email-submit'
import { constructCategories } from '../functions/categories'
import GeneralListSuccess from '../components/general-list-success'
import GeneralError from '../components/general-error'
import { StateUpdater, useState } from 'preact/hooks'
import { hasAtLeastOneDoubleOptInAction } from '../functions/actions'


enum CategoryNewsletterFlow {
    FORM = 'FORM',
    SUCCESS = 'SUCCESS',
    ERROR = 'ERROR'
}

interface CategoryNewsletterProps {
    emitter: mitt.Emitter
    rule: Rule
    email: string
    hasIdentity: boolean
    categories?: { id: string, name: string }[]
    consentCheckEnabled: boolean
    consentChecked: boolean,
    onConsentCheckChange: (checked: boolean) => void,
    eviAPI: EviAPI
}

export default function CategoryNewsletter (props: CategoryNewsletterProps){

    let initialCategories: Category[] = []

    const [email, setEmail]: [string, StateUpdater<string>] = useState(props.email)
    const [flow, setFlow]: [CategoryNewsletterFlow, StateUpdater<CategoryNewsletterFlow>] = useState(CategoryNewsletterFlow.FORM)
    const [validationErrors, setValidationErrors]: [{ email?: ValidationError[], consent?: ValidationError[] }, StateUpdater<{ email?: ValidationError[], consent?: ValidationError[] }>] =  useState({ email: [], consent: [] })
    const [categories, setCategories]: [Category[], StateUpdater<Category[]>] = useState(constructCategories(props.rule.actions, props.categories).map(c => ({...c})))
    const [subscriptions, setSubscriptions]: [Category[], StateUpdater<Category[]>] = useState([])
    const [revocations, setRevocations]: [Category[], StateUpdater<Category[]>] = useState([])

    const onCategoryChange = (e, categoryId) => {
        const itemToUpdate = categories.find(category => category.id === categoryId)
        if (e.target.checked) {
            itemToUpdate.status = hasAtLeastOneDoubleOptInAction(props.rule.doubleOptInType, props.rule.actions) ? PermissionLinkStatus.PENDING : PermissionLinkStatus.GRANTED
        } else {
            itemToUpdate.status = PermissionLinkStatus.REVOKED
        }
        setCategories([...categories])
    }

    const validate = () => {
        const validationErrors = {
            consent: validateConsentCheck(props.consentCheckEnabled, props.consentChecked),
            email: validateEmail(email, true),
        }
        setValidationErrors(validationErrors)
        return hasValidationErrors(validationErrors)
    }

    const onSubmit = async (e) => {
        e.preventDefault()
        if (!validate()) {
            return
        }
        const subscriptions = initialCategories
            .filter(category => !category.status || category.status === PermissionLinkStatus.REVOKED)
            .filter(category => categories.find(c => (c.status === PermissionLinkStatus.PENDING || c.status === PermissionLinkStatus.GRANTED) && c.id === category.id))
        const revocations = initialCategories
            .filter(category => category.status === PermissionLinkStatus.PENDING || category.status === PermissionLinkStatus.GRANTED)
            .filter(category => categories.find(c => c.status === PermissionLinkStatus.REVOKED && c.id === category.id))
        setSubscriptions(subscriptions)
        setRevocations(revocations)
        let response
        if (subscriptions.length) {
            response = await props.eviAPI.postPermissionLink({ruleIds: [props.rule.id], context: {categoryID: subscriptions.map(cat => cat.id).join(',')}})
        }
        if (revocations.length) {
            response = await props.eviAPI.deletePermissionLink({ruleIds: [props.rule.id], context: {categoryID: revocations.map(cat => cat.id).join(',')}})
        }
        if (response && response.features.EMAIL === 'BLACKLISTED') {
            setFlow(CategoryNewsletterFlow.ERROR)
        } else {
            setFlow(CategoryNewsletterFlow.SUCCESS)
            props.emitter.emit('widget-success')
        }
        initialCategories = [...categories.map(c => ({...c}))]
    }

    const onBackLink = () => {
        setSubscriptions([])
        setRevocations([])
        setFlow(CategoryNewsletterFlow.FORM)}

    const renderForm = () => {
        return <div class="evi-widget-content">
            <Title title={props.rule.data.title}/>
            <Description description={props.rule.data.text_above}/>
            <CategoryList text={props.rule.data}
                          categories={categories}
                          onSubmit={onCategoryChange}/>
            <div class="evi-widget-subscription">
                <EmailSubmit text={props.rule.data}
                             ruleId={props.rule.id}
                             email={email}
                             showLabel={true}
                             hasIdentity={props.hasIdentity}
                             validationErrors={validationErrors.email}
                             setEmail={setEmail}
                             onSubmit={onSubmit}/>
            </div>
            <ConsentInformation text={props.rule.data}
                                ruleId={props.rule.id}
                                consentCheckEnabled={props.consentCheckEnabled}
                                validationErrors={validationErrors.consent}
                                onConsentCheckChange={props.onConsentCheckChange}/>
        </div>
    }


    return <div id={`evi-widget-rule-${props.rule.id}`} class={`evi-widget evi-widget-type-category-newsletter ${props.rule.cssClass || ''}`}>
        {{
            [CategoryNewsletterFlow.FORM]: renderForm(),
            [CategoryNewsletterFlow.SUCCESS]: <GeneralListSuccess text={props.rule.data}
                                                                  isDoubleOptInEnabled={hasAtLeastOneDoubleOptInAction(props.rule.doubleOptInType, props.rule.actions)}
                                                                  subscriptions={subscriptions.map(subscribed => subscribed.name)}
                                                                  revocations={revocations.map(revoked => revoked.name)}
                                                                  onBackLink={onBackLink}/>,
            [CategoryNewsletterFlow.ERROR]: <GeneralError text={props.rule.data}/>
        }[flow]}
        <Footer text={props.rule.data}/>
    </div>
}
