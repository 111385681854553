import { Artist, ValidationError } from '../domain'
import { hasValidationErrors, validateConsentCheck, validateEmail } from './validation'
import { StateUpdater } from 'preact/hooks'

export interface TicketAlarmValidationErrors {
    artistSearch: ValidationError[],
    email: ValidationError[],
    consent: ValidationError[]
}

export enum TicketAlarmFlow {
    FORM = 'FORM',
    SUCCESS = 'SUCCESS',
    ERROR = 'ERROR'
}

export function ticketAlarmAdditionalClasses (rule, lastRemoved, artists: Artist[], flow?: TicketAlarmFlow, isActionDoubleOptIn?: boolean) {
    return [
        rule.cssClass ? rule.cssClass : '',
        lastRemoved === true ? 'evi-widget-last-artist-removed' : '',  // lastRemoved has a three valued logic
        lastRemoved === false ? 'evi-widget-artist-selected' : '',      // lastRemoved has a three valued logic
        artists === null ? '' : `evi-widget-permissions-granted-count-${ artists.filter(a => !a.pending).length}`,
        flow == TicketAlarmFlow.SUCCESS ? (isActionDoubleOptIn ? 'evi-widget-doi-sent' : 'evi-widget-success') : '',
        flow == TicketAlarmFlow.ERROR ? 'evi-widget-error' : ''
    ]
}

export function ticketAlarmOnAnimationEnd (setAnimateId) {
    setAnimateId(null)
}

export function ticketAlarmOnSelectedAutocomplete (artistList: Artist[], artistToAdd: Artist, isDoubleOptInEnabled, setAnimateId, setArtists, setLastRemoved) {
    artistToAdd.pending = isDoubleOptInEnabled
    const foundArtist = artistList.find(artistsPrev => artistsPrev.id === artistToAdd.id)
    foundArtist ? setAnimateId(foundArtist.id) : setArtists([...artistList, artistToAdd])
    setLastRemoved(false)
}

export function ticketAlarmValidate (artists, email, props: { consentCheckEnabled, consentChecked}, setValidationErrors) {
    const validationErrors = {
        consent: validateConsentCheck(props.consentCheckEnabled, props.consentChecked),
        email: validateEmail(email),
        artistSearch: !artists.length ? [ValidationError.ERROR_MISSINGDATA] : []
    }
    setValidationErrors(validationErrors)
    return hasValidationErrors(validationErrors)
}

export async function ticketAlarmOnSubmit (e, artists, email, props: { consentCheckEnabled, consentChecked, eviAPI, rule, emitter }, setFlow, setValidationErrors) {
    e.preventDefault()
    if (!ticketAlarmValidate(artists, email, props, setValidationErrors)) {
        return
    }
    const artistIds = artists.map(artist => artist.id)
    const response = await props.eviAPI.postPermissionLink({
        ruleIds: [props.rule.id],
        context: {artistID: artistIds.join(',')},
        email: email
    })
    if (response.features.EMAIL === 'BLACKLISTED') {
        setFlow(TicketAlarmFlow.ERROR)
    } else {
        setFlow(TicketAlarmFlow.SUCCESS)
        props.emitter.emit('widget-success')
    }
}

export function ticketAlarmOnSearchFocus (isFocus: boolean, setSearchFocus: StateUpdater<boolean>) {
    setSearchFocus(isFocus)
}
