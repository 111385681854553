import { Component, h } from 'preact'
import ValidationErrorList from './validation-error-list'

enum TextKey {
    consentLink = 'link_consent',
    consentLinkText = 'link_consent_text',
    textBefore = 'text_consent_before',
    textAfter = 'text_consent_after'
}

interface ConsentInformationProps {
    text: { [key: string]: string }
    ruleId: string
    consentCheckEnabled: boolean
    validationErrors?: string[]
    onConsentCheckChange?: (checked) => void
}

export default class ConsentInformation extends Component<ConsentInformationProps> {
    render () {
        return this.props.text[TextKey.consentLink] ? <div class="evi-widget-consent">
            {this.props.consentCheckEnabled &&
            <input id={`evi-widget-consent-${this.props.ruleId}`}
                   onClick={(e) => this.props.onConsentCheckChange((e.target as HTMLInputElement).checked)}
                   class={`evi-widget-consent-required ${  this.props.validationErrors && this.props.validationErrors.length ? 'evi-widget-input-error' : ''}`}
                   type="checkbox"/>}
            <label for={`evi-widget-consent-${this.props.ruleId}`} class={'evi-widget-consent-label'}>
                <span dangerouslySetInnerHTML={{__html: this.props.text[TextKey.textBefore]}}/>{' '}
                <a href={this.props.text[TextKey.consentLink]} target="_blank" dangerouslySetInnerHTML={{__html: this.props.text[TextKey.consentLinkText]}}/>{' '}
                <span dangerouslySetInnerHTML={{__html: this.props.text[TextKey.textAfter]}}/>{' '}
            </label>

            <ValidationErrorList text={this.props.text}
                                 validationErrors={this.props.validationErrors}/>
        </div> : null
    }
}
