import { h } from 'preact'
import { Artist } from '../domain'
import AgentInfo from './agent-info'
import { lastModifiedInfo, permissionLinkInfo, permissionLinkStatus } from '../functions/agent'

interface ArtistCollectionProps {
    artists: Artist[]
    animateId: string
    onAnimationEnd: () => void
    onRemoveArtist: (artist: Artist) => void
    agent?: string
}

export default function ArtistCollection (props: ArtistCollectionProps) {

    const artistsList = (props.artists || [])
        .map(artist =>
            <li class={'evi-widget-artist ' + (artist.pending ? 'evi-widget-artist-pending ' : '') + (artist.id === props.animateId ? 'evi-widget-artist-blink ' : '')}
                // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                // @ts-ignore TS2322 onanimationend all lowercase. see https://github.com/preactjs/preact/issues/1589
                onanimationend={props.onAnimationEnd}
                data-artist-id={artist.id}
                data-name={artist.name}>
                <span onClick={() => props.onRemoveArtist(artist)} class="evi-widget-close">{artist.name}</span>
                {!!props.agent && <AgentInfo lastModified={lastModifiedInfo(artist.action, (pl) => pl.value === artist.id)}
                                                  origin={permissionLinkInfo(artist.action, 'origin', (pl) => pl.value === artist.id)}
                                                  granted={permissionLinkInfo(artist.action, 'granted', (pl) => pl.value === artist.id)}
                                                  revoked={permissionLinkInfo(artist.action, 'revoked', (pl) => pl.value === artist.id)}
                                                  confirmed={permissionLinkInfo(artist.action, 'confirmed', (pl) => pl.value === artist.id)}
                                                  expires={permissionLinkInfo(artist.action, 'expires', (pl) => pl.value === artist.id)}
                                                  lastModifiedBy={permissionLinkInfo(artist.action, 'lastModifiedBy', (pl) => pl.value === artist.id)}
                                                  status={permissionLinkStatus(artist.action, (pl) => pl.value === artist.id)}/>}
            </li>)
    return artistsList.length ?
        <ul class="evi-widget-artist-list evi-widget-artist-list-result">
            {artistsList}
        </ul> : null
}
