import { Component, h } from 'preact'

interface DescriptionProps {
    description: string
}


export default class Description extends Component<DescriptionProps> {
    render () {
        return this.props.description ? <div class="evi-widget-description" dangerouslySetInnerHTML={{__html: this.props.description}}/> : null
    }
}
