import { Component, h } from 'preact'

interface TitleProps {
    title: string
}


export default class Title extends Component<TitleProps> {
    render () {
        return this.props.title ? <div class="evi-widget-title"><span dangerouslySetInnerHTML={{__html: this.props.title}}/></div> : null
    }
}
