import { Component, h } from 'preact'

interface AllPermissionsGrantedProps {
    text: { [key: string]: string }
}

export default class AllPermissionsGranted extends Component<AllPermissionsGrantedProps> {
    render () {
        return <div class="evi-widget-permitted">
            <div class="evi-widget-title-permitted" dangerouslySetInnerHTML={{__html: this.props.text.title_alreadyregistered}}/>
            <div class="evi-widget-description-permitted" dangerouslySetInnerHTML={{__html: this.props.text.text_alreadyregistered}}/>
        </div>
    }

}
