import { Component, h } from 'preact'

enum TextKey {
    footerText = 'text_below'
}
interface FooterProps {
    /**
     * key/value list of texts. Only the key 'text_below' will be used
     */
    text: { [key: string]: string }
}

export default class Footer extends Component<FooterProps> {
    render () {
        return this.props.text && this.props.text[TextKey.footerText]
            ? <div class="evi-widget-footer" dangerouslySetInnerHTML={{__html: this.props.text[TextKey.footerText]}}/>
            : null
    }
}
