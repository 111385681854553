import { h, render } from 'preact'
import WidgetsInitializer from './app/widgets-initializer'
import { Capture, WidgetOptions } from './app/domain'
import * as Mitt from 'mitt/dist/mitt.umd'
import { Emitter } from 'mitt'
import './polyfill'
import * as CustomEvent from 'custom-event'

import './styles/main.less'
import { api, EviAPI } from './app/evi-api'
import FormWidget from './app/widgets/FormWidget'

export class EviWidget {
    readonly emitter: mitt.Emitter
    private options: WidgetOptions
    constructor (readonly elem: Element, readonly $elem?: any) {
        this.elem = (typeof elem === 'string') ? document.querySelector(elem): elem
        this.emitter = new Mitt() as Emitter
    }

    // initialize async, to prevent to interfere with js from integrating page. e.g. when widget initialization throws error
    create = (options: WidgetOptions): EviWidget => {
        if (this.elem) {
            this.elem.addEventListener('evi-widget:init-async', () => this.createAsync(options))
            this.elem.dispatchEvent(new CustomEvent('evi-widget:init-async'))
        }
        return this
    }

    private createAsync = (options: WidgetOptions): EviWidget => {
        this.registerEventListener()
        this.options = options
        // Clear widget when reinitialized. Currently only in the test-client when the widget is reinitialized with different data.
        if (this.elem.getAttribute('evi-widget-initialized')) {
            render(null, this.elem)
        }
        render(<WidgetsInitializer options={options} emitter={this.emitter}/>, this.elem)
        this.elem.setAttribute('evi-widget-initialized', 'true')
        return this
    }

    registerEventListener = (): void => {
        if (this.$elem) {
            // jQuery only
            this.emitter.on('widget-ready', (args) => this.$elem.trigger('evi-widget:ready', args))
            this.emitter.on('widget-success', () => this.$elem.trigger('evi-widget:success'))
        } else {
            // no jQuery
            this.emitter.on('widget-ready', (args) => this.elem.dispatchEvent(new CustomEvent('evi-widget:ready', {detail: args})))
            this.emitter.on('widget-success', () => this.elem.dispatchEvent(new CustomEvent('evi-widget:success')))
        }
    }
    // emits save event for form widget
    save = (params: {error?: (msg: string) => void, success?: (response: {}) => void} = {success: () => {}, error: () => {}}): any => {
        let ret = null
        this.emitter.emit('save', {...params, mailInputSelector: this.options.mailInputSelector, result: (v) => ret = v})
        return ret
    }
    // emits capture event for form widget
    capture(): any {
        let ret = null
        this.emitter.emit('capture', {mailInputSelector: this.options.mailInputSelector, result: (v) => ret = v})
        return ret
    }
    // emits validate event for form widget
    validate = (): any => {
        let ret = null
        this.emitter.emit('validate', {mailInputSelector: this.options.mailInputSelector, result: (v) => ret = v})
        return ret
    }
    // emits add artist event for ticket alarm widgets
    addArtist = (artist: {artistId: string, name: string}): void => this.emitter.emit('addArtist', artist)

    /*
     * static save function to handle capture strings
     * First page: const capture = $('#app').data('EviWidget').capture();
     * Next Page (no widget configured): EviWidget.save(capture)
     */
    static save(captureStringBase64: string, params: {success?: (response?: {}) => void, timeout?: number} = {success: () => {}}): void | any {
        if (!captureStringBase64 || !captureStringBase64.length) {
            console.error('capture is empty')
            return
        }
        let captureString
        try {
            captureString = atob(captureStringBase64)
        } catch(e) {
            console.error('capture is not a valid base64 string', e)
            return
        }
        let capture: Capture
        try {
            capture = JSON.parse(captureString)
        } catch(e) {
            console.error('base64 decoded capture is not a valid object', e)
            return
        }
        const eviAPI: EviAPI = api(capture.options, capture.token, capture.revision, capture.identityFeatureValue)
        return FormWidget.doSave(eviAPI, capture.rules, capture.identityFeatureValue, params)
    }
}

// declare global access
declare const window: any
if (typeof window !== "undefined") {
    window.EviWidget = EviWidget
}

// jQuery shim
declare const $: any
if (typeof $ === "function") {
    $.fn.createEviWidget = function (options) {
        const widget = new EviWidget(this.get(0), this).create(options)
        this.data('EviWidget', widget)
        return this
    }
}
