import { Component, h } from 'preact'


interface BlacklistedProps {
    text: { [key: string]: string }
    blacklisted: boolean
}

export default class Blacklisted extends Component<BlacklistedProps> {
    render () {
        return this.props.blacklisted ? <div class="evi-widget-blacklisted-error" dangerouslySetInnerHTML={{__html: this.props.text.text_identity_feature_blacklisted}}/> : null
    }
}
