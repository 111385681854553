import { Component, h } from 'preact'

interface MinimizeControlProps {
    text: { [key: string]: string }
    minimized: boolean
    onToggleMinimize: () => void
}

export default class MinimizeControl extends Component<MinimizeControlProps> {
    render () {
        return <label class="evi-widget-toggle" onClick={this.props.onToggleMinimize}>
            <input title="toggle" type="checkbox" checked={this.props.minimized} onClick={this.props.onToggleMinimize}/>
            <span className="evi-widget-toggle-max">{this.props.text.maximize}</span>
            <span className="evi-widget-toggle-min">{this.props.text.minimize}</span>
        </label>
    }
}
