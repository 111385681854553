import { Component, h } from 'preact'

interface GeneralListSuccessProps {
    text: { [key: string]: string }
    isDoubleOptInEnabled: boolean
    subscriptions?: string[]
    revocations?: string[]
    onBackLink?: () => void
}

export default class GeneralListSuccess extends Component<GeneralListSuccessProps> {
    renderList = (items, prefix, suffix) => {
        if (items && items.length) {
            const listItems = items.map(subscribed => <li>{subscribed}</li>)
            return <div class="evi-widget-description-success">
                <span dangerouslySetInnerHTML={{__html: prefix}}/>
                <ul>{listItems}</ul>
                <span dangerouslySetInnerHTML={{__html: suffix}}/>
            </div>
        }
        return null
    }

    render () {
        const titleText = this.props.isDoubleOptInEnabled ? this.props.text.title_doi_sent : this.props.text.title_success
        const subscriptionPrefix = this.props.isDoubleOptInEnabled ? this.props.text.text_doi_sent_prefix : this.props.text.text_success_prefix
        const subscriptionSuffix = this.props.isDoubleOptInEnabled ? this.props.text.text_doi_sent_suffix : this.props.text.text_success_suffix
        const revocationPrefix = this.props.text.text_revoked_prefix
        const revocationSuffix = this.props.text.text_revoked_suffix
        const backText = this.props.isDoubleOptInEnabled ? this.props.text.text_doi_sent_back : this.props.text.text_success_back
        return [
            <div class="evi-widget-title-success" dangerouslySetInnerHTML={{__html: titleText}}/>,
            this.renderList(this.props.subscriptions, subscriptionPrefix, subscriptionSuffix),
            this.renderList(this.props.revocations, revocationPrefix, revocationSuffix),
            this.props.onBackLink ? <div class="evi-widget-reset" onClick={this.props.onBackLink} dangerouslySetInnerHTML={{__html: backText}}/> : null
        ]
    }
}
