import { useEffect } from 'preact/hooks'
import { ticketAlarmOnSelectedAutocomplete } from '../functions/ticketalarm'
import { isActionDoubleOptIn } from '../functions/actions'

export function useAddArtist(emitter, artists, doubleOptInType, action, setAnimateId, setArtists, setLastRemoved): void {
    useEffect(() => {
        const handler = (artistToAdd) => ticketAlarmOnSelectedAutocomplete(artists, {id: artistToAdd.artistId, name: artistToAdd.name},
            isActionDoubleOptIn(doubleOptInType, action), setAnimateId, setArtists, setLastRemoved)
        emitter.on('addArtist', handler)
        return () => emitter.off('addArtist', handler)
    }, [artists])
}
