import { Component, h } from 'preact'
import { Category, PermissionLinkStatus } from '../domain'
import LeverSwitch from './lever-switch'
import Title from './title'
import AgentInfo from './agent-info'
import { lastModifiedInfo, permissionLinkInfo, permissionLinkStatus } from '../functions/agent'

interface CategoryListProps {
    text: { [key: string]: string }
    categories: Category[]
    agent?: string
    onSubmit: (e: any, categoryId: string) => void
}

interface CategoryListState {
    changedCategories: string[]
}

export default class CategoryList extends Component<CategoryListProps, CategoryListState> {
    constructor (props) {
        super(props)
        this.setState({changedCategories: []})
    }

    onSubmit = (e, categoryId) => {
        this.setState({changedCategories: [...this.state.changedCategories, categoryId]})
        this.props.onSubmit(e, categoryId)
    }

    showAgentInfo = (categoryId: string) => {
        return !!this.props.agent && !this.state.changedCategories.includes(categoryId)
    }

    render () {
        const categories = this.props.categories.map(category => <li>
            <LeverSwitch text={this.props.text}
                         cssClasses={category.status === PermissionLinkStatus.PENDING ? 'evi-widget-switch-pending' : ''}
                         checked={category.status === PermissionLinkStatus.GRANTED || category.status === PermissionLinkStatus.PENDING}
                         value={category.id}
                         onSubmit={this.onSubmit}/>
            <Title title={category.name}/>
            {this.showAgentInfo(category.id) && <AgentInfo lastModified={lastModifiedInfo(category.action, (pl) => pl.value === category.id)}
                                                           origin={permissionLinkInfo(category.action, 'origin', (pl) => pl.value === category.id)}
                                                           granted={permissionLinkInfo(category.action, 'granted', (pl) => pl.value === category.id)}
                                                           revoked={permissionLinkInfo(category.action, 'revoked', (pl) => pl.value === category.id)}
                                                           confirmed={permissionLinkInfo(category.action, 'confirmed', (pl) => pl.value === category.id)}
                                                           expires={permissionLinkInfo(category.action, 'expires', (pl) => pl.value === category.id)}
                                                           lastModifiedBy={permissionLinkInfo(category.action, 'lastModifiedBy', (pl) => pl.value === category.id)}
                                                           status={permissionLinkStatus(category.action, (pl) => pl.value === category.id)}/>}
        </li>)

        return <ul class="evi-widget-categories evi-widget-switch-list">
            {categories}
        </ul>
    }
}
