import { Component, h } from 'preact'


interface ValidationErrorListProps {
    /**
     * Mapping of validation error keys to printable text.
     * If an error is not present here, the error will be omitted
     */
    text:  { [key: string]: string }
    /**
     * List of validation error keys
     */
    validationErrors: string[]
}

export default class ValidationErrorList extends Component<ValidationErrorListProps> {
    render () {
        return this.props.validationErrors && this.props.validationErrors.length ?
            <ul class="evi-widget-form-error">
                {this.props.validationErrors
                    .map(value => this.props.text[value])
                    .filter(value => !!value)
                    .map(value => <li>{value}</li>)}
            </ul> : null
    }
}
