/**
 * Collection of all polyfills
 */
import 'promise-polyfill/src/polyfill'
import 'cross-fetch/polyfill'
import './string.includes'
import './array.includes'
import './array.find'
import './array.findIndex'
import './object.values'
