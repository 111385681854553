import { Action, DoubleOptInType, PermissionLinkStatus } from '../domain'

export const isActionDoubleOptIn = (doubleOptInType: DoubleOptInType, action: Action): boolean => {
    return (doubleOptInType !== 'NONE') && action.doubleOptIn
}

export const hasAtLeastOneDoubleOptInAction = (doubleOptInType: DoubleOptInType, actions: Action[]): boolean => {
    return !!actions.find(action => isActionDoubleOptIn(doubleOptInType, action))
}

export const hasPendingPermissionLink = (doubleOptInType: DoubleOptInType, action: Action, changedActionIds: string[]): boolean => {
    const hasChanged = changedActionIds.find(changedActionId => changedActionId === action.id)
    return hasChanged ?
        isActionDoubleOptIn(doubleOptInType, action) && action.permissionLinkStatus === PermissionLinkStatus.PENDING :
        isActionDoubleOptIn(doubleOptInType, action) &&
        !!action.permissionLinks.find(pm => pm.status === PermissionLinkStatus.PENDING)
}

export const hasPermissionLinkGranted = (action: Action, value?: string): boolean => {
    return !!action.permissionLinks.find(pm => (!value || value.toString() === pm.value) && pm.status === PermissionLinkStatus.GRANTED)
}

export const hasAllPermissionLinksGranted = (actions: Action[], value?: string): boolean => {
    return !actions.find(action => !hasPermissionLinkGranted(action, value))
}
