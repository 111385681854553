import { h } from "preact"
import Footer from '../components/footer'
import { Rule, Template, ValidationError } from '../domain'
import ConsentInformation from '../components/consent-information'
import EmailSubmit from '../components/email-submit'
import Blacklisted from '../components/blacklisted'
import AllPermissionsGranted from '../components/all-permissions-granted'
import GeneralSuccess from '../components/general-success'
import GeneralError from '../components/general-error'
import Title from '../components/title'
import Description from '../components/description'
import { hasValidationErrors, validateConsentCheck, validateEmail } from '../functions/validation'
import { EviAPI } from '../evi-api'
import { StateUpdater, useState } from 'preact/hooks'
import { hasAllPermissionLinksGranted, hasAtLeastOneDoubleOptInAction } from '../functions/actions'


enum GeneralNewsletterFlow {
    FORM = 'FORM',
    ALL_GRANTED = 'ALL_GRANTED',
    SUCCESS = 'SUCCESS',
    ERROR = 'ERROR'
}

interface GeneralNewsletterProps {
    emitter: mitt.Emitter
    rule: Rule
    artistId: string
    email: string
    consentCheckEnabled: boolean
    consentChecked: boolean
    hasIdentity: boolean
    onConsentCheckChange: (checked: boolean) => void,
    eviAPI: EviAPI
}

export default function GeneralNewsletter(props: GeneralNewsletterProps) {

    const [email, setEmail]: [string, StateUpdater<string>] = useState(props.email)
    const [flow, setFlow]: [string, StateUpdater<string>] = useState(hasAllPermissionLinksGranted(props.rule.actions, this.props.artistId) ? GeneralNewsletterFlow.ALL_GRANTED : GeneralNewsletterFlow.FORM)
    const [validationErrors, setValidationErrors]: [{ email?: ValidationError[], consent?: ValidationError[] }, StateUpdater<{ email?: ValidationError[], consent?: ValidationError[] }>] = useState({
        email: [],
        consent: []
    })

    const validate = () => {
        const validationErrors = {
            consent: validateConsentCheck(props.consentCheckEnabled, props.consentChecked),
            email: validateEmail(email, true),
        }
        setValidationErrors(validationErrors)
        return hasValidationErrors(validationErrors)
    }

    const onSubmit = async (e) => {
        e.preventDefault()
        if (!validate()) {
            return
        }
        const response = await props.eviAPI.postPermissionLink({ruleIds: [props.rule.id], email: email})
        if (response.features.EMAIL === 'BLACKLISTED') {
            setFlow(GeneralNewsletterFlow.ERROR)
        } else {
            setFlow(GeneralNewsletterFlow.SUCCESS)
            props.emitter.emit('widget-success')
        }
    }

    const onBackLink = () => {
        setFlow(GeneralNewsletterFlow.FORM)
    }

    const renderForm = () => {
        return [
            <Title title={props.rule.data.title}/>,
            <Description description={props.rule.data.text_above}/>,
            <div class="evi-widget-subscription">
                <EmailSubmit text={props.rule.data}
                             ruleId={props.rule.id}
                             email={email}
                             showLabel={true}
                             hasIdentity={props.hasIdentity}
                             validationErrors={validationErrors.email}
                             setEmail={setEmail}
                             onSubmit={onSubmit}/>
            </div>,
            <Blacklisted text={props.rule.data} blacklisted={false}/>,
            <ConsentInformation text={props.rule.data}
                                ruleId={props.rule.id}
                                consentCheckEnabled={props.consentCheckEnabled}
                                validationErrors={validationErrors.consent}
                                onConsentCheckChange={props.onConsentCheckChange}/>
        ]
    }

    const additionalCssClasses = (): string[] => {
        const cssClasses = [
            props.rule.cssClass ? props.rule.cssClass : '',
            props.rule.template === Template.TICKET_ALARM_SINGLE ? 'evi-widget evi-widget-type-ticket-alarm-single' : 'evi-widget-type-general-newsletter'
        ]
        if (flow === GeneralNewsletterFlow.SUCCESS) {
            hasAtLeastOneDoubleOptInAction(props.rule.doubleOptInType, props.rule.actions) ? cssClasses.push('evi-widget-doi-sent') : cssClasses.push('evi-widget-success')
        }
        if (flow === GeneralNewsletterFlow.ALL_GRANTED) {
            cssClasses.push('evi-widget-permitted')
        }
        return cssClasses
    }


    return <div id={`evi-widget-rule-${props.rule.id}`} class={`evi-widget ${additionalCssClasses().join(' ')}`}>
        {{
            [GeneralNewsletterFlow.FORM]: renderForm(),
            [GeneralNewsletterFlow.ALL_GRANTED]: props.rule.template === Template.TICKET_ALARM_SINGLE ? <AllPermissionsGranted text={props.rule.data}/> : null,
            [GeneralNewsletterFlow.SUCCESS]: <GeneralSuccess text={props.rule.data}
                                                             isDoubleOptInEnabled={hasAtLeastOneDoubleOptInAction(props.rule.doubleOptInType, props.rule.actions)}/>,
            [GeneralNewsletterFlow.ERROR]: <GeneralError text={props.rule.data}
                                                         onBackLink={onBackLink}/>
        }[flow]}
        {(props.rule.template === Template.TICKET_ALARM_SINGLE || flow !== GeneralNewsletterFlow.ALL_GRANTED) && <Footer text={props.rule.data}/>}
    </div>
}
