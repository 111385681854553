import { Component, h } from 'preact'

interface LeverSwitchProps {
    text: { [key: string]: string }
    checked: boolean
    value: string
    cssClasses?: string
    onSubmit: (e: any, value: string) => void
}

export default class LeverSwitch extends Component<LeverSwitchProps> {
    render () {
        return <label className={`evi-widget-switch ${  this.props.cssClasses}`}>
            <input className="evi-widget-checkbox" type="checkbox"
                   onClick={(e) => this.props.onSubmit(e, this.props.value)}
                   value={this.props.value}
                   checked={this.props.checked}/>
            <span className="evi-widget-switch-lever">
                <span className="evi-widget-switch-left" dangerouslySetInnerHTML={{__html: this.props.text.yes}}/>
                <span className="evi-widget-switch-center">&nbsp;</span>
                <span className="evi-widget-switch-right" dangerouslySetInnerHTML={{__html: this.props.text.no}}/>
            </span>
        </label>
    }
}
